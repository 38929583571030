import * as React from "react"
import { Link } from "gatsby"
import scrollTo from "./scrollTo"


const Header = ({ page }) => {

  const openMenu = () => {
    document.querySelector('.overlay--menu').classList.add('active')
  } 

  return (
  <>
    <header className='masthead bb1'>
      <div className='max-1600 flex p20 ma align-center'>
        <Link to="/" >
          <div className='logo--main'/>
        </Link>
        <p className='m0 button mla m-show' role='presentation' onClick={openMenu}>Menu</p>
        <Menu page={page} take={"https://pulsi.com.au/contact"} />
      </div>
    </header>
    <Overlay take={"https://pulsi.com.au/contact"} />
  </>
  )
}

export default Header

const Menu = ({page}) => (
  <div className='menu mla flex align-center m-hide'>
    {( page ?
      <>
        <p className='m0 mr30 pointer ul-link' role='presentation' onClick={() => scrollTo('#how')} >How it works</p>
        <p className='m0 mr30 pointer ul-link' role='presentation' onClick={() => scrollTo('#who')} >Who it’s for</p>
        <p className='m0 mr30 pointer ul-link' role='presentation' onClick={() => scrollTo('#why')} >Why Pulsi</p>
      </> :
      <>
        <Link to='/#how' className='m0 mr30 ul-link'>How it works</Link>
        <Link to='/#who' className='m0 mr30 ul-link'>Who it’s for</Link>
        <Link to='/#why' className='m0 mr30 ul-link'>Why Pulsi</Link>
      </>
    )}
    <Link to='/contact' className='m0 button fill'>Find out more</Link>
  </div>
)

const Overlay = ({take}) => {

  const closeMenu = () => {
    document.querySelector('.overlay--menu').classList.remove('active');
  } 

  return (
  <div className='overlay--menu flex flex-wrap'>
    <div className='p20 flex align-center w-100 mba inner'>
      <Link to="/" >
        <div className='logo--main menu'/>
      </Link>
      <p className='m0 button mla white' role='presentation' onClick={closeMenu}>Close</p>
    </div>
    <div className='mta p20 white mb40 inner'>
      <div className='m0 h0 mb40'>
        <Link to='/#how' onClick={closeMenu} className='m0 mr30'>How it works</Link><br/>
        <Link to='/#who' onClick={closeMenu} className='m0 mr30'>Who it’s for</Link><br/>
        <Link to='/#why' onClick={closeMenu} className='m0 mr30'>Why Pulsi</Link><br/>
      </div>
      <div className='flex  mb40'>
        <Link to={take} className='m0 button white fill mr10'>Find out more</Link>
      </div>
    </div>
  </div>
  )
}
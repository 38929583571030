import * as React from "react"
import { Link } from "gatsby"
import scrollTo from "./scrollTo"

const Footer = ({page}) => {
  return (
  <footer className='p20'>
    <div className='max-1600 ma mt40 mb40 flex m-wrap m-m0 m-mt40'>
      <div className='w-50 m-100'>
        <div className='pb40 m-bb1 black m-pb40 m-mb40'>
          <Link to='/'>
          <div className='logo--footer mb40 mt40 m-m0'/>
          </Link>
        </div>
        <div className='m-hide'>
          <p className='m0'>© {new Date().getFullYear()} Pulsi All Rights Reserved</p>
          <p className='m0'>Terms and Conditions</p>
        </div>
      </div>
      <div className='w-100  max-550 mla flex m-wrap'>
        <div className='w-50 large flex m-wrap m-100'>
          <div className='w-50'>
            {( page ?
              <>
                <p className='m0 mb10 pointer ul-link inline' role='presentation' onClick={() => scrollTo('#how')}>How it works</p><br/>
                <p className='m0 mb10 pointer ul-link inline' role='presentation' onClick={() => scrollTo('#who')}>Who it’s for</p><br/>
                <p className='m0 mb10 pointer ul-link inline' role='presentation' onClick={() => scrollTo('#why')}>Why Pulsi</p><br/>
              </> :
              <>
                <Link to='/#how' className='m0 mb10 inline ul-link'>How it works</Link><br/>
                <Link to='/#who' className='m0 mb10 inline ul-link'>Who it’s for</Link><br/>
                <Link to='/#why' className='m0 mb10 inline ul-link'>Why Pulsi</Link><br/>
              </>
            )}
            <Link to='/contact' className='m0 ul-link inline'>Contact</Link>
          </div>
          <div className='w-50'>
            {<p className='m0 mb10'><a href={"https://www.instagram.com/Pulsi_au/"} rel="noreferrer" target='_blank' className='m0 ul-link mb10'>Instagram</a></p>}
            {<p className='m0'><a href={"https://www.linkedin.com/company/pulsi"} rel="noreferrer" target='_blank' className='m0 ul-link'>Linkedin</a></p>}
          </div>
        </div>
        <div className='w-50 m-100 large flex m-mt40 m-mb40'>
          <div className='flex mba mla'>
            <Link to='/contact' className='m0 button fill'>Find out more</Link>
          </div>
        </div>
        <div className='m-show w-100'>
          <div className='w-100 pt40'>
            <p className='m0'>© {new Date().getFullYear()} Pulsi All Rights Reserved</p> 
            <p className='m0'>Terms and Conditions</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer

import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import "../scss/site.scss"

const Layout = ({ children, page }) => {
   

  return (
    <>
      <Header page={page} />
      <main>{children}</main>
      <Footer page={page} />
    </>
  )
}

export default Layout
